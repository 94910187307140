@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400&display=swap');

*{
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-family: Mulish !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error {
  border-color: red;
}


   /* @media screen and (max-width:  320px){
       .formResponsive {
        display: block;
        width: 100%;
        height: 100vh;
        color: red;
       }

      /* @media screen and (max-width: 320px){
        .formResponsive {
          display: block;
          width: 100%;
          height: 100vh;
        } */
    

    


  
