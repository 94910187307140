/*Testimonial*/
* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  /* overflow-x:hidden;  */
  /* overflow: auto; */
}

/* .testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
} */

.slick-prev {
  left: 0% !important;
  z-index: 1;
}
.slick-next {
  right: 0% !important;
  z-index: 1;
}

/* .testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
} */
/* .testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
} */

.signstyle{
  color:white
}

.signstyle:hover{
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #14a800;
}

.header{
  position: fixed;
  width: 100%;
  height: 6rem;
  padding: 3rem 7rem;  
  border-bottom: 0.2rem solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  z-index: 999;
  overflow: hidden;
  top: 0;
}



.slider ul{
  width:100%;
  transition:1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin-bottom:  0;
}


.slider ul li{
  list-style: none;
  color:black;
  z-index: 100;
  margin-left: 30px;
}

.slider ul li a{
  font-size: 16px;
  text-decoration: none;
  color:black;
  font-weight: bolder;
  z-index: 100;
  /* text-transform: uppercase; */
}
.menu-icon {
  display:none;
  color:black;
  z-index:999;
  font-size:50px;
  justify-content: flex-end;
  cursor: pointer;
}

.header .logo{
  z-index: 99;
}

.green{
  background-color: green;
  padding:10px 40px;
  border-radius: 5px;
}

@media (max-width:900px){
  .header{
    padding: 0.5rem 0.5rem; 
  }

  .slider{
      position:fixed;
      left: 0;
      width:100%;
      top:-100%;
      background-color: white;
      transition:1s ease;
      min-height: 100vh;
      /* min-height: -webkit-fill-available; */
  }
  
  .slider ul{
      display:flex;
      flex-direction: column;
      align-items: center;
      margin-top:170px; 
      padding-left: 0px;
      margin-left: 0px;
  }
  
  .slider.active{
      top:0%;
      transition:1s ease;
      min-height: 100vh;
      justify-content: flex-end;
      /* min-height: -webkit-fill-available; */
  
  }
  
  .slider ul li{
      list-style: none;
      color:black;
      margin-bottom: 32px;
      margin-left: 0px;
      /* font-size: 50px; */
  }
  
  .slider ul li a{
      font-size: 25px;
      text-decoration: none;
      color:black
  }
  .menu-icon {
    display:flex;
    color:black;
    font-size:50px;
    justify-content: flex-end;
    cursor: pointer;
  }
 
  
  .closed{
      display: flex;
      cursor: pointer;
      color: black;
      justify-content: flex-end;
      width: 100%;
      font-size:50px;
  }
   
  .green{
      background-color: green;
      padding:10px 40px;
      border-radius: 5px;
      min-width:40%
  }

  
}

@media (max-width: 420px){
  .slider{
      position:fixed;
      min-width:100%;
      top:-100%;
      background-color: white;
      transition:1s ease;
      min-height: 100vh;
      /* min-height: -webkit-fill-available; */
  }
  
  .slider ul{
      display:flex;
      flex-direction: column;
      align-items: center;
      margin-top:100px; 
      /* justify-content: flex-end; */
  }
  
  .slider.active{
      top:0%;
      transition:1s ease;
      min-height: 100vh;
      justify-content: flex-end;
      /* min-height: -webkit-fill-available; */
  
  }
  
  .slider ul li{
      list-style: none;
      color:black;
  }
  
  .slider ul li a{
      font-size: 25px;
      text-decoration: none;
      color:black
  }

  .menu-icon {
    display:flex;
    color:black;
    font-size:30px;
    justify-content: flex-end;
    cursor: pointer;
  }

  .menu-icon svg {
    width: 28px;
    height: 28px;
  }
  
  .closed{
    display: flex;
    cursor: pointer;
    color: black;
    justify-content: flex-end;
    width: 100%;
    font-size:50px;
  }

  .green{
      background-color: green;
      padding:10px 40px;
      border-radius: 5px;
      min-width:40%
  }

  
}
